const QuestionsMatri = [
    {
      id: 0,
      title: "La comunicación entre las diferentes áreas de la empresa fluye de manera efectiva.",
    },
    {
      id: 1,
      title: "Considero que en la empresa existe buena comunicación entre colaboradores, compañeros y jefes.",
    },
    {
      id: 2,
      title: "Mi superior inmediato me comunica claramente qué espera de mi trabajo.",
    },
    {
      id: 3,
      title: "La empresa nos mantiene informados acerca de temas y cambios importantes.",
    },
    {
      id: 4,
      title: "Los líderes tienen una visión clara de hacia dónde va la organización y qué hacer para lograrlo.",
    },
    {
      id: 5,
      title: "Cuando quiero conversar con mi superior inmediato, muestra interés por escucharme.",
    },
    {
      id: 6,
      title: "Confío en el conocimiento y la experiencia de mi superior inmediato.",
    },
    {
      id: 7,
      title: "Mi superior inmediato ayuda a crear un ambiente agradable de trabajo en mi área.",
    },
    {
      id: 8,
      title: "Mi superior inmediato trata a sus colaboradores de una forma justa (con hechos y datos y sin preferencias).",
    },
    {
      id: 9,
      title: "Mi superior inmediato nos dice frecuentemente lo que hicimos bien y lo que debemos mejorar.",
    },
    {
      id: 10,
      title: "Me siento cómodo/a de expresar mis opiniones y preocupaciones a mi superior inmediato.",
    },
    {
      id: 11,
      title: "Me siento capacitado para desempeñar las funciones asignadas en mi puesto de trabajo.",
    },
    {
      id: 12,
      title: "Tengo conocimiento de los diferentes procesos en relación con mis funciones.",
    },
    {
      id: 13,
      title: "Entiendo claramente cuáles son mis responsabilidades y tareas dentro de mi puesto de trabajo.",
    },
    {
      id: 14,
      title: "Tengo claro los objetivos y metas que debo cumplir en mi trabajo.",
    },
    {
      id: 15,
      title: "En mi área existe apoyo y colaboración entre todo el personal.",
    },
    {
      id: 16,
      title: "En mi área existe un ambiente de confianza entre las personas.",
    },
    {
      id: 17,
      title: "En la empresa se trabaja en equipo entre las diferentes áreas.",
    },
    {
      id: 18,
      title: "Los líderes de la empresa son ejemplo del trabajo en equipo.",
    },
    {
      id: 19,
      title: "Mi superior inmediato mantiene buenas relaciones con los integrantes en mi área.",
    },
    {
      id: 20,
      title: "Considero que existen posibilidades reales de desarrollo profesional dentro de la empresa.",
    },
    {
      id: 21,
      title: "Aquí me ofrecen capacitación u otras formas de desarrollo para crecer laboralmente.",
    },
    {
      id: 22,
      title: "Mi superior inmediato me comenta sobre mi desempeño en la empresa y se preocupa por mi crecimiento personal y profesional.",
    },
    {
      id: 23,
      title: "Mi superior inmediato me brinda acompañamiento constante en mi desarrollo.",
    },
    {
      id: 24,
      title: "Mi superior inmediato es justo cuando evalúa mi desempeño.",
    },
    {
      id: 25,
      title: "La evaluación de desempeño me permite conocer mis fortalezas y debilidades laborales.",
    },
    {
      id: 26,
      title: "Recibes comentarios útiles de quien esta a cargo de tu evaluación de desempeño.",
    },
    {
      id: 27,
      title: "Recibes la capacitación necesaria para realizar tu trabajo de manera segura y evitar accidentes.",
    },
    {
      id: 28,
      title: "Consideras que la empresa proporciona un entorno de trabajo seguro y saludable.",
    },
    {
      id: 29,
      title: "Te sientes seguro/a (protegido/a) al realizar tus tareas diarias en el lugar de trabajo.",
    },
    {
      id: 30,
      title: "Sientes que tienes acceso a equipos de protección personal (EPP) adecuados para tu trabajo.",
    },
    {
      id: 31,
      title: "Te sientes alentado/a a informar sobre posibles riesgos para la seguridad o incidentes en el trabajo.",
    },
    {
      id: 32,
      title: "Los competencias organizacionales (como la empresa espera que yo me comporte al realizar mi trabajo) han sido comunicados claramente a toda la empresa.",
    },
    {
      id: 33,
      title: "Comozco la misión y visión de la empresa.",
    },
    {
      id: 34,
      title: "Si me preguntaran, podría explicar con claridad las competencias organizacionales de la empresa (como la empresa espera que yo me comporte al realizar mi trabajo).",
    },
    {
      id: 35,
      title: "Me siento parte de la cultura (valores y creencias) que se vive en la empresa.",
    },
    {
      id: 36,
      title: "Me entusiasma pensar en continuar mi carrera profesional en la empresa.",
    },
    {
      id: 37,
      title: "Si se presentara la oportunidad de obtener otro empleo, donde las condiciones sean similares, permanecería en esta empresa.",
    },
    {
      id: 38,
      title: "En mi área nos sentimos motivados de trabajar en la empresa.",
    },
    {
      id: 39,
      title: "En mi área nos sentimos comprometidos con nuestro trabajo y aportes a la empresa.",
    },
    {
      id: 40,
      title: "La empresa reconoce y valora los aportes de los colaboradores.",
    },
    {
      id: 41,
      title: "Los trabajadores en la empresa reciben reconocimiento (no necesariamente monetario) por el trabajo bien hecho.",
    },
    {
      id: 42,
      title: "Considero que mi superior inmediato reconoce el buen trabajo y el esfuerzo que realizo.",
    },
    {
      id: 43,
      title: "Los Lideres de la empresa reconocen y valoran a las personas que logran un buen trabajo y con buenos resultados.",
    },
    {
      id: 44,
      title: "Siento que mi participación es importante en el desarrollo y logro de objetivos de mi área.",
    },
    {
      id: 45,
      title: "Cuento con los elementos de trabajo necesarios para desempeñar mis funciones.",
    },
    {
      id: 46,
      title: "Recibes la capacitación necesaria para utilizar correctamente las herramientas y equipos proporcionados por la empresa.",
    },
    {
      id: 47,
      title: "La empresa brinda ayuda cuando se encuentran problemas con las herramientas de trabajo.",
    },
    {
      id: 48,
      title: "Las herramientas de trabajo que utilizas te permiten cumplir con los estándares de calidad y eficiencia requeridos por la empresa.",
    },
    {
      id: 49,
      title: "Estoy satisfecho/a con las instalaciones físicas de la empresa (espacios de trabajo, oficinas, áreas comunes, etc.)",
    },
    {
      id: 50,
      title: "Considero que el espacio donde trabajo es adecuado para realizar bien mi trabajo.",
    },
    {
      id: 51,
      title: "Las instalaciones y espacios de trabajo están bien mantenidas y limpias.",
    },
    {
      id: 52,
      title: "Tomando todo en consideración, yo diría que este es un buen lugar donde trabajar.",
    },
    {
      id: 53,
      title: "A continuación, te agradeceremos nos comentes que cosas crees que ayudarían a crear un mejor ambiente de trabajo.",
    },
  ];
     
    export default QuestionsMatri;