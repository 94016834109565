import { useEffect, useRef } from 'react';

const useIdleTimer = (onIdle, idleTime = 300000) => {
  const timerRef = useRef(null);
  
  const resetTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(onIdle, idleTime);
  };

  useEffect(() => {
    const handleEvents = () => {
      resetTimer();
    };

    window.addEventListener('mousemove', handleEvents);
    window.addEventListener('keypress', handleEvents);
    window.addEventListener('scroll', handleEvents);
    window.addEventListener('click', handleEvents);

    resetTimer();

    return () => {
      clearTimeout(timerRef.current);
      window.removeEventListener('mousemove', handleEvents);
      window.removeEventListener('keypress', handleEvents);
      window.removeEventListener('scroll', handleEvents);
      window.removeEventListener('click', handleEvents);
    };
  }, []);

  return null;
};

export default useIdleTimer;
