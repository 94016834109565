import React from 'react';
import './index.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Inicio from './components/Inicio';
import Login from './components/login';
import Preguntas from './components/Preguntas';
import ProtectedRoute from './components/ProtectRoute';
import Final from './components/Final';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route element={<ProtectedRoute />}>
          <Route index element={<Inicio />} />
          <Route path="/preguntas" element={<Preguntas />} />
          <Route path="/final" element={<Final />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;