const Questions = [
  {
    id: 0,
    title: "La comunicación entre las diferentes áreas de la empresa fluye de manera efectiva.",
  },
  {
    id: 1,
    title: "Considero que en la empresa existe buena comunicación entre colaboradores, compañeros y jefes.",
  },
  {
    id: 2,
    title: "Mi superior inmediato me comunica claramente qué espera de mi trabajo.",
  },
  {
    id: 3,
    title: "La empresa nos mantiene informados acerca de temas y cambios importantes.",
  },
  {
    id: 4,
    title: "Los líderes tienen una visión clara de hacia dónde va la organización y qué hacer para lograrlo.",
  },
  {
    id: 5,
    title: "Cuando quiero conversar con mi superior inmediato, muestra interés por escucharme.",
  },
  {
    id: 6,
    title: "Confío en el conocimiento y la experiencia de mi superior inmediato.",
  },
  {
    id: 7,
    title: "Mi superior inmediato ayuda a crear un ambiente agradable de trabajo en mi área.",
  },
  {
    id: 8,
    title: "Mi superior inmediato trata a sus colaboradores de una forma justa (con hechos y datos y sin preferencias).",
  },
  {
    id: 9,
    title: "Mi superior inmediato realiza retroalimentación sobre mi desempeño en la empresa, nos dice frecuentemente lo que hicimos bien y lo que debemos mejorar.",
  },
  {
    id: 10,
    title: "Me siento cómodo/a de expresar mis opiniones y preocupaciones a mi superior inmediato.",
  },
  {
    id: 11,
    title: "Me siento capacitado para desempeñar las funciones asignadas en mi cargo.",
  },
  {
    id: 12,
    title: "Tengo conocimiento de los diferentes procesos en relación con mis funciones.",
  },
  {
    id: 13,
    title: "Entiendo claramente cuáles son mis responsabilidades y tareas dentro de mi puesto de trabajo.",
  },
  {
    id: 14,
    title: "Tengo claro los objetivos que debo cumplir en mi trabajo.",
  },
  {
    id: 15,
    title: "En mi área existe apoyo y colaboración entre todo el personal.",
  },
  {
    id: 16,
    title: "En mi área existe un ambiente de confianza entre las personas.",
  },
  {
    id: 17,
    title: "En la empresa se trabaja en equipo entre las diferentes áreas.",
  },
  {
    id: 18,
    title: "Los líderes de la empresa promueven, con su ejemplo, el trabajo en equipo.",
  },
  {
    id: 19,
    title: "Mi superior inmediato mantiene buenas relaciones con los integrantes del área.",
  },
  {
    id: 20,
    title: "Considero que existen posibilidades reales de desarrollo profesional dentro de la empresa.",
  },
  {
    id: 21,
    title: "Aquí me ofrecen capacitación u otras formas de desarrollo para crecer laboralmente.",
  },
  {
    id: 22,
    title: "Mi superior inmediato se preocupa por mi crecimiento personal y profesional.",
  },
  {
    id: 23,
    title: "Mi superior inmediato es justo cuando evalúa mi desempeño.",
  },
  {
    id: 24,
    title: "La evaluación de desempeño me permite conocer mis fortalezas y debilidades laborales.",
  },
  {
    id: 25,
    title: "Recibo retroalimentación constructiva y útil durante el proceso de evaluación de desempeño.",
  },
  {
    id: 26,
    title: "Me siento seguro/a (protegido/a) al realizar mis tareas diarias en el lugar de trabajo.",
  },
  {
    id: 27,
    title: "Considero que la empresa proporciona un entorno de trabajo seguro y saludable.",
  },
  {
    id: 28,
    title: "Recibo la capacitación necesaria para realizar mi trabajo de manera segura y evitar accidentes.",
  },
  {
    id: 29,
    title: "Siento que tengo acceso a equipos de protección personal (EPP) adecuados para mi trabajo.",
  },
  {
    id: 30,
    title: "Me siento alentado/a a informar sobre posibles riesgos para la seguridad o incidentes en el trabajo.",
  },
  {
    id: 31,
    title: "Si me preguntaran, podría explicar con claridad los valores  de la empresa.",
  },
  {
    id: 32,
    title: "Aquí se aplican los valores y principios fundamentales de la empresa.",
  },
  {
    id: 33,
    title: "Los valores organizacionales han sido comunicados claramente a todo nivel en la empresa.",
  },
  {
    id: 34,
    title: "Me siento parte de la cultura que se vive en la empresa.",
  },
  {
    id: 35,
    title: "Me entusiasma pensar en continuar mi carrera profesional en la empresa.",
  },
  {
    id: 36,
    title: "Si se presentara la oportunidad de obtener otro empleo, donde las condiciones sean similares, permanecería en esta empresa.",
  },
  {
    id: 37,
    title: "En mi área nos sentimos motivados de trabajar en la empresa.",
  },
  {
    id: 38,
    title: "En mi área nos sentimos comprometidos con nuestro trabajo y aportes a la empresa.",
  },
  {
    id: 39,
    title: "La empresa reconoce y valora los aportes de los colaboradores.",
  },
  {
    id: 40,
    title: "Todos tienen la oportunidad de recibir un reconocimiento (no necesariamente monetario) por la labor bien hecha.",
  },
  {
    id: 41,
    title: "Considero que mi superior inmediato reconoce el buen trabajo y el esfuerzo que realizo.",
  },
  {
    id: 42,
    title: "Los Lideres de la empresa reconocen y valoran a las personas que muestran un desempeño óptimo y con buenos resultados.",
  },
  {
    id: 43,
    title: "Siento que mi participación es importante en el desarrollo y logro de objetivos de mi área.",
  },
  {
    id: 44,
    title: "Cuento con los elementos de trabajo necesarios para desempeñar mis funciones.",
  },
  {
    id: 45,
    title: "Recibes la capacitación necesaria para utilizar correctamente las herramientas y equipos proporcionados por la empresa.",
  },
  {
    id: 46,
    title: "La empresa brinda soporte técnico o asistencia en caso de que encuentres problemas con las herramientas de trabajo.",
  },
  {
    id: 47,
    title: "Las herramientas de trabajo que utilizo me permiten cumplir con los estándares de calidad y eficiencia requeridos por la empresa.",
  },
  {
    id: 48,
    title: "Estoy satisfecho/a con las instalaciones físicas de la empresa (espacios de trabajo, oficinas, áreas comunes, etc.)",
  },
  {
    id: 49,
    title: "Considero que la distribución del espacio de trabajo es adecuada para realizar mis funciones de manera eficiente.",
  },
  {
    id: 50,
    title: "Las instalaciones y espacios de trabajo están bien mantenidas y limpias.",
  },
  {
    id: 51,
    title: "La empresa proporciona recursos adecuados para manejar el estrés en el trabajo.",
  },
  {
    id: 52,
    title: "Me siento apoyado/a por mis superiores y compañeros cuando experimento estrés en el trabajo.",
  },
  {
    id: 53,
    title: "Siento que tengo suficiente tiempo libre para mi mismo/a fuera del trabajo.",
  },
  {
    id: 54,
    title: "Tengo la flexibilidad necesaria para manejar las demandas de mi vida personal junto con mi trabajo.",
  },
  {
    id: 55,
    title: "Creo que mi empresa promueve un ambiente de trabajo saludable.",
  },
  {
    id: 56,
    title: "Siento que la cultura de la empresa promueve el autocuidado y el bienestar de los empleados.",
  },
  {
    id: 57,
    title: "Tomando todo en consideración, yo diría que este es un buen lugar donde trabajar.",
  },
  {
    id: 58,
    title: "A continuación, te agradeceremos nos comentes sobre aquellos aspectos que consideres que ayudarán a propiciar un mejor ambiente de trabajo.",
  },
  {
    id: 59,
    title: "Menciona máximo 3 dimensiones que para ti son las que Lombardi deberia seguir trabajando:",
  },
];
   
  export default Questions;