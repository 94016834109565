import React, { useState, useEffect } from 'react';
import { linkServidor } from '../services/apirest';
import { useNavigate } from "react-router-dom";
import ImagenLateral from '../assets/img/ImagenLateral.png';
import HRDCircular from '../assets/img/HRDCircular.png';
import fondomatri from '../assets/img/fondomatri.png';
import Matri from '../assets/img/logologin.png';
import Swal from 'sweetalert2';
import axios from 'axios';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [valor, setValor] = useState(0);

  const Login = (e) => {
    setValor(1);
    e.preventDefault();

    if (email === '' || password === '') {
      setValor(0);
        Swal.fire({
          icon: "error",
          title: "Correo o contraseña vacios",
          // confirmButtonColor: '#ef483f',
          confirmButtonColor: '#97D313',
        });
      return;
    }

    fetch(`${linkServidor}/login`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email : email,
        password : password,
      }),
    })
    .then((response) => response.json())
    .then((json)=>{
      console.log(json);
      if(json.success == false){{
        setValor(0);
        Swal.fire({
          icon: "error",
          title: "Correo o contraseña invalidos",
          // confirmButtonColor: '#ef483f',
          confirmButtonColor: '#97D313',
        });
        return;
      }}
      if(json.success == true){
        localStorage.setItem("token", json.data.access_token);
        if(json.data.user.empresas == 2 || json.data.user.empresas == 1 ){
          localStorage.setItem("usuario", JSON.stringify(json.data.user.id));
          localStorage.setItem("empresa", JSON.stringify(json.data.user.empresas));

          axios.get(`${linkServidor}/getiduser/${json.data.user.id}`, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              'Authorization': `Bearer ${json.data.access_token}` 
            }
          })
          .then((response) => {
            console.log(response);
            console.log(response.data.data);
    
            const info = response.data.data;
    
            const transformedArray = info.map(item => ({
              codigo: item.id_pregunta,
              res: item.valor
            }));
    
            const transformedJsonString = JSON.stringify(transformedArray);
            localStorage.setItem('datos', transformedJsonString);
            navigate('/');  
    
          })
          .catch((err)=>{
            console.log(err);
          });

        }else{
          navigate('/');
        }
      }
    })  
    .catch((err)=>{
      console.log(err);
    });
  }

  useEffect(() => {
    localStorage.removeItem('token');
    const triggerPassword = document.querySelector('.fa-eye');

    const togglePasswordVisibility = () => {
      const passwordInput = triggerPassword.previousElementSibling;
      if (passwordInput.getAttribute('type') === 'password') {
        passwordInput.setAttribute('type', 'text');
        triggerPassword.classList.remove('fa-eye');
        triggerPassword.classList.add('fa-eye-slash');
      } else if (passwordInput.getAttribute('type') === 'text') {
        passwordInput.setAttribute('type', 'password');
        triggerPassword.classList.remove('fa-eye-slash');
        triggerPassword.classList.add('fa-eye');
      }
    };

    triggerPassword.addEventListener('click', togglePasswordVisibility);

    return () => {
      triggerPassword.removeEventListener('click', togglePasswordVisibility);
    };
  }, []);

  return (
    <div className='contenedor'>
      <div className='portada_login'>
        {/* <img src={HRDCircular} style={{ position: "absolute", width: "5%", margin: "1%" }}/> */}
        <img src={fondomatri} style={{ height: "100%" }}/>
        <p className='texto_login'>© 2024 HRD | Desarrollado por Karl Pearson Asociados</p>
      </div>
      <div className='segundaportada_login'>
        <div className="main__container">
          <form className="formulario_login form__container" onSubmit={(e) => {Login(e)}}>
            <div className="form__input" style={{ marginBottom: "5%" }}>
            {/* <img src={HRDCircular} style={{ width: "25%" }}/> */}
              <img src={Matri} style={{ width: "100%" }}/>
              <h2 style={{ fontWeight: "bold", fontSize: "20px", color: "rgba(123, 118, 118, 0.69)", marginTop: "3%", marginBottom: "0px" }}>Login</h2>
            </div>
            <div className="form__input" style={{ marginBottom: "4%" }}>
              <label className='label_login' style={{ color: "#97D313" }}>Email</label>
              <input type="email" name="email" style={{ borderRadius: "30px" }} onChange={(e) => { setEmail(e.target.value) }} />
            </div>
            <div className="form__input">
              <label className='label_login' style={{ color: "#97D313" }}>Password</label>
              <input type="password" name="password" style={{ borderRadius: "30px" }} onChange={(e) => { setPassword(e.target.value) }}/>
              <span className="fa fa-eye"></span>
            </div>
            <div className="form__input" style={{ marginBottom: "8%" }}>
              {valor !== 0 && (
                <div className='boton_carga_ingreso'>
                  <div className="spinner-border" role="status" style={{ color: "white", marginTop: "3%" }}>
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
              {valor === 0 && (
                <input type="submit" name="submit" className='boton_ingreso_estilo' value="Ingresar"/>
              )}
            </div>
          </form>
          <p className='segundotexto_login'>© 2024 HRD | Desarrollado por Karl Pearson Asociados</p>
        </div>
      </div>
    </div>
  )
}

export default Login