import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import lombardi from '../assets/img/LombardiLogoCOLOR.png';
import matri from '../assets/img/matr.png'; 
import logo from '../assets/img/logopres.png'; 
import { linkServidor } from '../services/apirest';
import axios from 'axios';
import useIdleTimer from './useIdleTimer';
// import Preguntas from './Preguntas';

function Inicio() {
  const navigate = useNavigate();
  const [activar, setActivar] = useState();

  const IDUsuario = JSON.parse(localStorage.getItem('usuario'));
  const IDEmpresa = JSON.parse(localStorage.getItem('empresa'));

  const Sesioncaducada = () => {
    console.log('Sesión expirada por inactividad');
    let token = localStorage.getItem("token");
    
    // Verificar si el token existe
    if (!token) {
      console.log('No se encontró un token, redirigiendo al login');
      navigate('/login');
      return;
    }
    
    // Si el token existe, proceder con la solicitud de axios
    axios.get(`${linkServidor}/logout`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}` 
      }
    })
    .then((response) => {
      console.log(response);
      localStorage.removeItem('empresa');
      localStorage.removeItem('token');
      localStorage.removeItem('usuario');
      navigate('/login');
    })
    .catch((err)=>{
      console.log(err);
    });
  };

  useIdleTimer(Sesioncaducada, 300000);

  const getEmpresaActual = () =>{
    const usuario = String(IDUsuario);
    if(IDEmpresa == "2"){
      let token = localStorage.getItem("token");
      axios.get(`${linkServidor}/verificarespuestasmatri/${usuario}`, {
        headers: {
          'Authorization': `Bearer ${token}` 
        }
      })
      .then((response) => {
        console.log(response);
        if (response.data.data == 'completo') {
          setActivar(1);
        }else if(response.data.data == 'proceso'){
          setActivar(2);
        } else {
          setActivar(0);
        }
      })
      .catch((err)=>{
        console.log(err);
      });
    }else{
      let token = localStorage.getItem("token");
      axios.get(`${linkServidor}/verificarespuestas/${usuario}`, {
        headers: {
          'Authorization': `Bearer ${token}` 
        }
      })
      .then((response) => {
        console.log(response);
        if (response.data.data == 'completo') {
          setActivar(1);
        }else if(response.data.data == 'proceso'){
          setActivar(2);
        } else {
          setActivar(0);
        }
      })
      .catch((err)=>{
        console.log(err);
      });
    }
  }

  const sesion = () =>{
    let token = localStorage.getItem("token");
    axios.get(`${linkServidor}/logout`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}` 
      }
    })
    .then((response) => {
      // localStorage.removeItem('datos');
      localStorage.removeItem('empresa');
      localStorage.removeItem('token');
      localStorage.removeItem('usuario');
      navigate('/login');
    })
    .catch((err)=>{
      console.log(err);
    });
  }

  useEffect(() => {
    getEmpresaActual();

    const handleBeforeUnload = () => {
      // Custom logic to handle the refresh
      // Display a confirmation message or perform necessary actions

      console.log('recargo');
      localStorage.clear();
      sessionStorage.clear();
      navigate('/login');
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
  }, []);

  return (
    <div className="wrapper">
      <div className="main p-3 contenido_inicio">
        {/* <img src={logo}  className='segundo_logo'/> */}
        {IDEmpresa === "1" && (
          <img src={lombardi} className='logo_inicio'/>
        )}
        {IDEmpresa === "2" && (
          <img src={matri}  className='segundologo_inicio'/>
        )}
        <div className="text-center inicio_texto">
          <h1 className='titulo_inicio'>
            ENCUESTA DE CLIMA LABORAL
          </h1>

          {IDEmpresa === "1" && (
            <div>
              <p style={{ textAlign: "justify", padding: "0 10%", marginBottom: "2%", fontSize: "18px" }}>
                Gracias por participar en nuestra Encuesta de Clima Organizacional. Tu opinión es fundamental para ayudarnos a comprender mejor cómo te sientes en relación con tu entorno laboral y 
                cómo podemos mejorar como organización para brindarte un ambiente de trabajo más satisfactorio y productivo. Esta encuesta es completamente anónima y confidencial. No se asociarán tus 
                respuestas con tu identidad, lo que te permite expresar tus opiniones con total franqueza. Por favor, sé sincero/a y reflexivo/a en tus respuestas, ya que esto nos ayudará a identificar 
                áreas de fortaleza y oportunidades de mejora.
              </p>
              <p style={{ textAlign: "justify", padding: "0 10%", marginBottom: "2%", fontSize: "18px" }}>
                La encuesta consta de una serie de preguntas que abordan diversos aspectos del clima laboral, como la comunicación, el liderazgo, el trabajo en equipo, el desarrollo profesional y el
                ambiente físico, entre otros. Tu participación es importante y te animamos a realizarla para que podamos obtener una imagen completa y precisa del clima organizacional.
              </p>
              <p style={{ textAlign: "justify", padding: "0 10%", marginBottom: "1%", fontSize: "18px" }}>
                Por favor, ten en cuenta que no hay respuestas correctas o incorrectas en esta encuesta. Lo que buscamos son tus percepciones y experiencias personales.
              </p>
              <p style={{ textAlign: "justify", padding: "0 10%", marginBottom: "1%", fontSize: "18px" }}>
                Gracias nuevamente por tu participacion y contribucion a la mejora continua de nuestra organización.
              </p>
            </div>
          )}

          {IDEmpresa === "2" && (
            <div>
              <p style={{ textAlign: "justify", padding: "0 10%", marginBottom: "2%", fontSize: "18px" }}>
                Gracias por participar en nuestra Encuesta de Clima Organizacional. Tu opinión es fundamental para ayudarnos a comprender mejor cómo te sientes en relación con tu entorno laboral y 
                cómo podemos mejorar como organización para brindarte un ambiente de trabajo más satisfactorio y productivo. Esta encuesta es completamente anónima y confidencial. No se asociarán tus 
                respuestas con tu identidad, lo que te permite expresar tus opiniones con total franqueza. Por favor, sé sincero/a y reflexivo/a en tus respuestas, ya que esto nos ayudará a identificar 
                áreas de fortaleza y oportunidades de mejora. 
              </p>
              <p style={{ textAlign: "justify", padding: "0 10%", marginBottom: "2%", fontSize: "18px" }}>
                La encuesta consta de una serie de preguntas que abordan diversos aspectos del clima laboral, como la comunicación, el liderazgo, el trabajo en equipo, el desarrollo profesional y el 
                ambiente físico, entre otros. Tu participación es voluntaria, pero te animamos encarecidamente a completarla para que podamos obtener una imagen completa y precisa del clima organizacional.
              </p>
              <p style={{ textAlign: "justify", padding: "0 10%", marginBottom: "1%", fontSize: "18px" }}>
                Por favor, ten en cuenta que no hay respuestas correctas o incorrectas en esta encuesta. Lo que buscamos son tus percepciones y experiencias personales.
              </p>
              <p style={{ textAlign: "justify", padding: "0 10%", marginBottom: "1%", fontSize: "18px" }}>
                Gracias nuevamente por tu participación y contribución a la mejora continua de nuestra organización.
              </p>
            </div>
          )}
          
          {IDEmpresa === "1" && (
            <p style={{ textAlign: "justify", padding: "0 10%", marginBottom: "1%", fontSize: "18px" }}>
              Rellene el recuadro al lado de la acción colocando un número del 1 al 4. Según la siguiente leyenda:  <br></br>
              1 (Totalmente en desacuerdo) <br></br> 2 (En desacuerdo) <br></br> 3 (De acuerdo) <br></br> 4 (Totalmente de acuerdo)                    
            </p>
          )}
          
          {IDEmpresa === "2" && (
            <p style={{ textAlign: "justify", padding: "0 10%", marginBottom: "1%", fontSize: "18px" }}>
              Rellene el recuadro al lado de la acción colocando un número del 1 al 5. Según la siguiente leyenda:  <br></br>
              1 (Totalmente en desacuerdo) <br></br> 2 (En desacuerdo) <br></br> 3 (Ni de acuerdo ni en desacuerdo) <br></br> 4 (De acuerdo) <br></br> 5 (Totalmente de acuerdo)                    
            </p>
          )}
          
          {activar === 0 && (
            <Link to={`/preguntas`}>
              <button className="btn btn-outline-success px-4 py-2 fw-bold" style={{ fontSize: "25px" }}> Empezar </button>
            </Link>
          )}
          {activar === 1 && (
            <p style={{ color: "red", fontSize: "18px" }}>Gracias por tu participación</p>
          )}
          {activar === 2 && (
            <Link to={`/preguntas`}>
              <button className="btn btn-outline-success px-4 py-2 fw-bold" style={{ fontSize: "25px" }}> Continuar </button>
            </Link>
          )}
        </div>
        <div className='botones_home'>
          {/* <Link to={`/`} className='boton_homes'>
            <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" fill="currentColor" className="lni lni-exit" style={{ fontSize: "35px", marginLeft: "2.5%", color: "black" }} viewBox="0 0 16 16">
              <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5z"/>
            </svg>
            <span style={{ display: "block", marginLeft: "2.5%" }}>Home</span>
          </Link>
          <br></br> */}
          <a onClick={()=>sesion()} className='boton_salir' style={{ textDecoration: "none" }}>
            <i className="lni lni-exit" style={{ fontSize: "35px", marginLeft: "2%", color: "black" }}></i>
            <span style={{ display: "block", marginLeft: "2.5%", color: "black" }}>Salir</span>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Inicio