import React, { useRef, useEffect } from 'react';
import '../assets/preguntas.css';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { linkServidor } from '../services/apirest';
import useIdleTimer from './useIdleTimer';

function QuestionCompMatri({ QuestionsMatri, selectedRes, CampoTexto }) {
  const navigate = useNavigate();
  const { title, id } = QuestionsMatri;
  const textareaRef = useRef(null);

  console.log(selectedRes, CampoTexto);
  
  const Sesioncaducada = () => {
    console.log('Sesión expirada por inactividad');
    let token = localStorage.getItem("token");
    
    // Verificar si el token existe
    if (!token) {
      console.log('No se encontró un token, redirigiendo al login');
      navigate('/login');
      return;
    }
    
    // Si el token existe, proceder con la solicitud de axios
    axios.get(`${linkServidor}/logout`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}` 
      }
    })
    .then((response) => {
      console.log(response);
      localStorage.removeItem('empresa');
      localStorage.removeItem('token');
      localStorage.removeItem('usuario');
      navigate('/login');
    })
    .catch((err)=>{
      console.log(err);
    });
  };
  
  useIdleTimer(Sesioncaducada, 300000);

  // Función para traducir el valor al inglés
  const translateResToEnglish = (res) => {
    switch (res) {
      case '1':
        return 'one';
      case '2':
        return 'two';
      case '3':
        return 'three';
      case '4':
        return 'four';
      case '5':
        return 'five';
      default:
        return '';
    }
  };

  useEffect(() => {
    if (selectedRes) {
      const translatedRes = translateResToEnglish(selectedRes);
      const inputToCheck = document.getElementById(translatedRes);
      if (inputToCheck) {
        inputToCheck.checked = true;
      }
    }
  }, [selectedRes]);

  const handleClick = (e) => {
    const labelId = e.target.id;
    let valorNumerico;
    switch (labelId) {
      case 'one':
        valorNumerico = '1';
        break;
      case 'two':
        valorNumerico = '2';
        break;
      case 'three':
        valorNumerico = '3';
        break;
      case 'four':
        valorNumerico = '4';
        break;
      case 'five':
        valorNumerico = '5';
        break;
      default:
        valorNumerico = '';
    }
    addRespuestas(id, valorNumerico);
  };

  const handleBlur = () => {
    if (CampoTexto >= 53 && textareaRef.current) {
      const valorNumerico = textareaRef.current.value;
      addRespuestas(CampoTexto, valorNumerico);  // Usar CampoTexto como id y textarea value como valorNumerico
    }
  };

  function addRespuestas(id, valorNumerico) {
    // Crear un objeto con los datos del producto
    const newObjeto = { codigo: id, res: valorNumerico };

    // Recuperar el array de productos desde localStorage, o crear uno nuevo si no existe
    let datos = JSON.parse(localStorage.getItem('datos')) || [];

    // Buscar el índice del objeto existente con el mismo ID
    const index = datos.findIndex(item => item.codigo === id);

    if (index !== -1) {
      // Reemplazar el objeto existente
      datos[index] = { ...datos[index], res: valorNumerico };
    } else {
      // Agregar el nuevo objeto al array
      datos.push(newObjeto);
    }

    // Guardar el array actualizado en localStorage
    localStorage.setItem('datos', JSON.stringify(datos));
  }

  return (
    <div className="wrapper espacio_preguntas">
      <div className="question" style={{ zIndex: "111" }}>
        <p className="fw-bold" style={{ display: "flex" }}>{id + 1} / 54</p> 

        {CampoTexto <= 52 && (
          <div>
            <p className="fw-bold preguntas_rotativo">{title}</p>
            <div>
              <input type="radio" name="box" id="one" value="1" onClick={handleClick} />
              <input type="radio" name="box" id="two" value="2" onClick={handleClick} />
              <input type="radio" name="box" id="three" value="3" onClick={handleClick} />
              <input type="radio" name="box" id="four" value="4" onClick={handleClick} />
              <input type="radio" name="box" id="five" value="5" onClick={handleClick} />
              <label htmlFor="one" className="box first" style={{ zIndex: "111" }}>
                <div className="course"><span className="circle" style={{ zIndex: "111" }} /> <span className="subject" style={{ zIndex: "111" }}>(1) Totalmente en desacuerdo</span></div>
              </label>
              <label htmlFor="two" className="box second" style={{ zIndex: "111" }}>
                <div className="course"><span className="circle" style={{ zIndex: "111" }} /> <span className="subject" style={{ zIndex: "111" }}>(2) En desacuerdo</span></div>
              </label>
              <label htmlFor="three" className="box third" style={{ zIndex: "111" }}>
                <div className="course"><span className="circle" style={{ zIndex: "111" }} /> <span className="subject" style={{ zIndex: "111" }}>(3) Ni de acuerdo ni en desacuerdo</span></div>
              </label>
              <label htmlFor="four" className="box forth" style={{ zIndex: "111" }}>
                <div className="course"><span className="circle" style={{ zIndex: "111" }} /> <span className="subject" style={{ zIndex: "111" }}>(4) De acuerdo</span></div>
              </label>
              <label htmlFor="five" className="box fifth" style={{ zIndex: "111" }}>
                <div className="course"><span className="circle" style={{ zIndex: "111" }} /> <span className="subject" style={{ zIndex: "111" }}>(5) Totalmente de acuerdo</span></div>
              </label>
            </div>
          </div>
        )}

        {CampoTexto >= 53 && (  
          <div>
            <p className="fw-bold preguntas_rotativo">{title}</p>
            <div className="form-floating" style={{ zIndex: "111" }}>
              <textarea className="form-control" id="floatingTextarea2" style={{ height: "200px", zIndex: "111" }} ref={textareaRef} onBlur={handleBlur}></textarea>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default QuestionCompMatri;