import React from 'react';
import { linkServidor } from '../services/apirest';
import { useNavigate, Link } from "react-router-dom";
import axios from 'axios';
import lombardi from '../assets/img/LombardiLogoCOLOR.png';
import matri from '../assets/img/matr.png';
import logo from '../assets/img/logopres.png';
import useIdleTimer from './useIdleTimer';

function Final() {
  const navigate = useNavigate();
  const IDEmpresa = JSON.parse(localStorage.getItem('empresa'))?JSON.parse(localStorage.getItem('empresa')):0;

  const Sesioncaducada = () => {
    console.log('Sesión expirada por inactividad');
    let token = localStorage.getItem("token");

    // Verificar si el token existe
    if (!token) {
      console.log('No se encontró un token, redirigiendo al login');
      navigate('/login');
      return;
    }

    // Si el token existe, proceder con la solicitud de axios
    axios.get(`${linkServidor}/logout`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      }
    })
    .then((response) => {
      console.log(response);
      localStorage.removeItem('empresa');
      localStorage.removeItem('token');
      localStorage.removeItem('usuario');
      navigate('/login');
    })
    .catch((err)=>{
      console.log(err);
    });
  };

  useIdleTimer(Sesioncaducada, 300000);

  const sesion = () =>{
    let token = localStorage.getItem("token");
    axios.get(`${linkServidor}/logout`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      }
    })
    .then((response) => {
      console.log(response);
      // localStorage.removeItem('datos');
      localStorage.removeItem('empresa');
      localStorage.removeItem('token');
      localStorage.removeItem('usuario');
      navigate('/login');
    })
    .catch((err)=>{
      console.log(err);
    });
  }

  return (
    <div className="wrapper">
      <div className="main p-3" style={{ backgroundColor: "#F2F2F2", height: "100vh" }}>
        {IDEmpresa === "1" && (
          <img src={lombardi} className='logo_inicio'/>
        )}
        {IDEmpresa === "2" && (
          <img src={matri}  className='segundologo_inicio'/>
        )}
        {/* <img src={logo}  className='segundo_logo'/> */}
        <h1 className='gracias_participacion'>¡GRACIAS POR TU PARTICIPACIÓN!</h1>
        <div className='botones_home'>
          <Link to={`/`} className='boton_homes'>
            <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" fill="currentColor" className="lni lni-exit" style={{ fontSize: "35px", marginLeft: "2.5%", color: "black" }} viewBox="0 0 16 16">
              <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5z"/>
            </svg>
            <span style={{ display: "block", marginLeft: "2.5%" }}>Home</span>
          </Link>
          <br></br>
          <a onClick={()=>sesion()} className='boton_salir' style={{ textDecoration: "none" }}>
            <i className="lni lni-exit" style={{ fontSize: "35px", marginLeft: "2%", color: "black" }}></i>
            <span style={{ display: "block", marginLeft: "2.5%", color: "black" }}>Salir</span>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Final