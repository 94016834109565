import React, { useState, useEffect } from 'react';
import QuestionComp from './QuestionComp';
import QuestionCompMatri from './QuestionCompMatri';
import Questions from './Questions';
import QuestionsMatri from './QuestionsMatri';
import '../assets/preguntas.css';
import Swal from 'sweetalert2';
import { linkServidor } from '../services/apirest';
import { useNavigate, Link } from "react-router-dom";
import axios from 'axios';
import lombardi from '../assets/img/LombardiLogoCOLOR.png'; 
import matri from '../assets/img/matr.png';
import logo from '../assets/img/logopres.png';
import useIdleTimer from './useIdleTimer';

function Preguntas() {
  const [number, setNumber] = useState(JSON.parse(localStorage.getItem('datos'))?JSON.parse(localStorage.getItem('datos')).length:0);
  const [selectedRes, setSelectedRes] = useState(null);
  const [CampoTexto, setCampoTexto] = useState(1);
  const [activar, setActivar] = useState(0);
  const navigate = useNavigate();
  console.log(number);

  const IDUsuario = JSON.parse(localStorage.getItem('usuario'));
  const IDEmpresa = JSON.parse(localStorage.getItem('empresa'));
  const iddepregunta = JSON.parse(localStorage.getItem('iddepregunta'));
  const respuestadepregunta = JSON.parse(localStorage.getItem('respuestadepregunta'));
  
  const Sesioncaducada = () => {
    console.log('Sesión expirada por inactividad');
    let token = localStorage.getItem("token");
    
    // Verificar si el token existe
    if (!token) {
        console.log('No se encontró un token, redirigiendo al login');
        navigate('/login');
        return;
    }
    
    // Si el token existe, proceder con la solicitud de axios
    axios.get(`${linkServidor}/logout`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}` 
      }
    })
    .then((response) => {
      console.log(response);
      localStorage.removeItem('empresa');
      localStorage.removeItem('token');
      localStorage.removeItem('usuario');
      navigate('/login');
    })
    .catch((err)=>{
      console.log(err);
    });
  };

  useIdleTimer(Sesioncaducada, 300000);

  const getEmpresaActual = () =>{
    const usuario = String(IDUsuario);
    if(IDEmpresa == "2"){
      let token = localStorage.getItem("token");
      axios.get(`${linkServidor}/getsegundaencuesta`, {
        headers: {
          'Authorization': `Bearer ${token}` 
        }
      })
      .then((response) => {
        console.log(response.data.data);
        const Respuestas = response.data.data;
  
        const usuariosIgualA2 = Respuestas.filter(resultado => resultado.user === usuario);
        console.log(usuariosIgualA2);
        if (usuariosIgualA2.length > 0) {
          setActivar(1);
        } else {
          setActivar(0);
        }
      })
      .catch((err)=>{
        console.log(err);
      });
    }else{
      let token = localStorage.getItem("token");
      axios.get(`${linkServidor}/getencuesta`, {
        headers: {
          'Authorization': `Bearer ${token}` 
        }
      })
      .then((response) => {
        console.log(response);
        const Respuestas = response.data.data;
  
        const usuariosIgualA2 = Respuestas.filter(resultado => resultado.user === usuario);
        if (usuariosIgualA2.length > 0) {
          setActivar(1);
        } else {
          setActivar(0);
        }
      })
      .catch((err)=>{
        console.log(err);
      });
    }
  }

  const handleIncrement = () => {
    if (parseInt(CampoTexto) >= 58) {// Limpiar textarea si existe
      const textarea = document.querySelector('textarea');
      const selectedValue = textarea.value;

      if (selectedValue.trim() === '') {
        const selectedValue = 'null';

        setNumber(number + 1);
        setCampoTexto(number + 1);

        handleIncrementsave(selectedValue);
        console.log('pasoooooooooooooooooooo 58', selectedValue);
        return;
      }

      if (textarea) {
        textarea.value = ''; // Limpiar el contenido del textarea
      }

      setNumber(number + 1);
      setCampoTexto(number + 1);
      
      handleIncrementsave(selectedValue);
      console.log('pasoooooooooooooooooooo 58', selectedValue);

      return;
    }

    // Verificar si hay un input radio seleccionado
    const selectedOption = document.querySelector('input[name="box"]:checked');
    if (!selectedOption) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Por favor escoger una opción",
        // confirmButtonColor: '#ef483f',
        confirmButtonColor: '#97D313',
      });
      return;
    }

    // Limpiar todas las opciones seleccionadas
    const allOptions = document.querySelectorAll('input[name="box"]');
    allOptions.forEach(option => {
      option.checked = false;
    });

    Swal.fire({
      icon: "success",
      title: "Cargando Datos...",
      html: 'Por favor, espere.',
      allowOutsideClick: false,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading();
      }
    });

    setNumber(number + 1);
    setCampoTexto(number + 1);

    console.log('aaaaaaaaaaaa', number);
    const selectedValue = selectedOption ? selectedOption.value : null;
    console.log('elegido', selectedValue)

    handleIncrementsave(selectedValue);
    console.log('estamos en menor a 58', selectedValue);
  };

  const handleSave = () =>{
    if (parseInt(CampoTexto) == 59) {
      const textarea = document.querySelector('textarea');
      const selectedValue = textarea.value;

      if (selectedValue.trim() === '') {
        const selectedValue = 'null';

        handleIncrementsave(selectedValue);
        console.log('pasoooooooooooooooooooo 59', selectedValue);
        navigate('/final');
        return;
      }
      
      handleIncrementsave(selectedValue);
      console.log('pasoooooooooooooooooooo 59', selectedValue);
      navigate('/final');
      return;
    }
  }

  const handleSaveMatri = () =>{
    if (parseInt(CampoTexto) == 53) {
      const textarea = document.querySelector('textarea');
      const selectedValue = textarea.value;

      if (selectedValue.trim() === '') {
        const selectedValue = 'null';
      
        handleIncrementsave(selectedValue);
        console.log('pasoooooooooooooooooooo 53', selectedValue);
        navigate('/final');
        return
      }
      
      handleIncrementsave(selectedValue);
      console.log('pasoooooooooooooooooooo 53', selectedValue);
      navigate('/final');
      return;
    }
  }

  const handleIncrementsave = (selectedValue) => {
    console.log(selectedValue, number, IDEmpresa, IDUsuario);
    let token = localStorage.getItem("token");
    fetch(`${linkServidor}/postiduser`, {
      method: "POST",
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        valor: selectedValue,
        id_pregunta: number,
        id_empresa: IDEmpresa,
        id_user: IDUsuario,
      }),
    })
    .then((response) => response.json())
    .then((json)=>{
      console.log(json);
      Swal.close(); 
    })
    .catch((err) => {
      console.log(err);
    });
  }

  const handleDecrement = () => {
    if (number === 0) return;
    setCampoTexto(number - 1);
    
    setNumber(prevNumber => {
      const newNumber = prevNumber - 1;

      // Obtener el valor de la respuesta seleccionada
      const selectedOption = document.querySelector('input[name="box"]:checked');
      const selectedValue = selectedOption ? selectedOption.value : null;
      setSelectedRes(selectedValue);

      // Verificar si existe el número en localStorage
      const datos = JSON.parse(localStorage.getItem('datos')) || [];
      const storedData = datos.find(item => item.codigo === Questions[newNumber].id);
      if (storedData) {
        // Activar el checked basado en el valor almacenado
        setTimeout(() => {
          const optionToCheck = document.querySelector(`input[name="box"][value="${storedData.res}"]`);
          if (optionToCheck) {
            optionToCheck.checked = true;
          }
        }, 0);
      }

      return newNumber;
    });
  };

  const sesion = () =>{
    let token = localStorage.getItem("token");
    axios.get(`${linkServidor}/logout`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}` 
      }
    })
    .then((response) => {
      console.log(response);
      // localStorage.removeItem('datos');
      localStorage.removeItem('empresa');
      localStorage.removeItem('token');
      localStorage.removeItem('usuario');
      navigate('/login');
    })
    .catch((err)=>{
      console.log(err);
    });
  }

  useEffect(() => {
    getEmpresaActual();
    // Verificar y establecer el checked cuando el componente se monte o el número cambie
    const datos = JSON.parse(localStorage.getItem('datos')) || [];
    const storedData = datos.find(item => item.codigo === Questions[number].id);
    if (storedData) {
      const optionToCheck = document.querySelector(`input[name="box"][value="${storedData.res}"]`);
      if (optionToCheck) {
        optionToCheck.checked = true;
      }
    }
  }, [number]);

  return (
    <div className="wrapper">
      <div className="main p-3" style={{ backgroundColor: "#F2F2F2", height: "100vh" }}>
        {IDEmpresa === "1" && (
          <img src={lombardi} className='logo_inicio'/>
        )}
        {IDEmpresa === "2" && (
          <img src={matri}  className='segundologo_inicio'/>
        )}
        {/* <img src={logo}  className='segundo_logo'/> */}
        {activar === 0 && (
          <div className="text-center espaciado_top">
            <div className="row">
              {IDEmpresa === "1" && (
                <div className="col-12">
                  <QuestionComp Questions={Questions[number]} selectedRes={selectedRes} CampoTexto={CampoTexto} />
                  <div className="buttons" style={{ width: "80%", margin: "1% auto" }}>
                    <div className='col-6' style={{ display: "flex" }}>
                      {number !== 0 && (
                        <button className="btn btn-outline-primary px-4 py-2 fw-bold" style={{ fontSize: "20px", zIndex: "111" }} onClick={handleDecrement}> Anterior </button>
                      )}
                    </div>
                    <div className='col-6' style={{ display: "contents" }}>
                      {number <= 58 && (
                        <button className="btn btn-outline-success px-4 py-2 fw-bold" style={{ fontSize: "20px", zIndex: "111" }} onClick={handleIncrement}> Siguiente </button>
                      )}

                      {number === 59 && (
                        <button className="btn btn-outline-success px-4 py-2 fw-bold" style={{ fontSize: "20px", zIndex: "111" }} onClick={handleSave}>Guardar</button>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {IDEmpresa === "2" && (
                <div className="col-12">
                  <QuestionCompMatri QuestionsMatri={QuestionsMatri[number]} selectedRes={selectedRes} CampoTexto={CampoTexto} />
                  <div className="buttons" style={{ width: "80%", margin: "1% auto" }}>
                    <div className='col-6' style={{ display: "flex" }}>
                      {number !== 0 && (
                        <button className="btn btn-outline-primary px-4 py-2 fw-bold" style={{ fontSize: "20px", zIndex: "111" }} onClick={handleDecrement}> Anterior </button>
                      )}
                    </div>
                    <div className='col-6' style={{ display: "contents" }}>
                      {number <= 52 && (
                        <button className="btn btn-outline-success px-4 py-2 fw-bold" style={{ fontSize: "20px", zIndex: "111" }} onClick={handleIncrement}> Siguiente </button>
                      )}

                      {number === 53 && (
                        <button className="btn btn-outline-success px-4 py-2 fw-bold" style={{ fontSize: "20px", zIndex: "111" }} onClick={handleSaveMatri}>Guardar</button>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {activar !== 0 && (
          <h1 className='gracias_participacion'>¡GRACIAS POR SU PARTICIPACIÓN!</h1>
        )}
        <div className='botones_home'>
          <Link to={`/`} className='boton_homes'>
            <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" fill="currentColor" className="lni lni-exit" style={{ fontSize: "35px", marginLeft: "2.5%", color: "black" }} viewBox="0 0 16 16">
              <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5z"/>
            </svg>
            <span style={{ display: "block", marginLeft: "2.5%" }}>Home</span>
          </Link>
          <br></br>
          <a onClick={()=>sesion()} className='boton_salir' style={{ textDecoration: "none" }}>
            <i className="lni lni-exit" style={{ fontSize: "35px", marginLeft: "2%", color: "black" }}></i>
            <span style={{ display: "block", marginLeft: "2.5%", color: "black" }}>Salir</span>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Preguntas;
