import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
// import isTokenExpired from './isTokenExpired';

// const ProtectedRoute = () => {
//   const token = localStorage.getItem('token');

//   console.log('Token:', token); // Verifica el token al recuperarlo

//   if (!token || isTokenExpired(token)) {
//     console.log('No token found or token expired, redirecting to login'); 
//     return <Navigate to="/login" />;
//   }

//   return <Outlet />;
// };

// export default ProtectedRoute;

const ProtectedRoute = ({ children, redirectTo = '/login' }) => {
  const isAuthenticated = localStorage.getItem('token');
  
  console.log(isAuthenticated);
  if (isAuthenticated) {
    return children ? children : <Outlet />;
  } else {
    return <Navigate to={redirectTo} />;
  }
};

export default ProtectedRoute;
